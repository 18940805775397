// actions/universityActions.js

import axios from 'axios';
import {
  LOGIN_SUCCESS,
  TOKEN_SUCCESS,
  UNIVERSITIES_REQUEST,
  UNIVERSITIES_SUCCESS,
  UNIVERSITIES_FAILURE,
  FETCH_BATCHES_REQUEST,
  FETCH_BATCHES_SUCCESS,
  FETCH_BATCHES_FAILURE,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  FETCH_SEM_REQUEST,
  FETCH_SEM_SUCCESS,
  FETCH_SEM_FAILURE,
  FETCH_EXAM_REQUEST,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAM_FAILURE,
  FETCH_GRADES_REQUEST,
  FETCH_GRADES_SUCCESS,
  FETCH_GRADES_FAILURE,
  FETCH_MANUAL_TEMPLATE_REQUEST,
  FETCH_MANUAL_TEMPLATE_SUCCESS,
  FETCH_MANUAL_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  UPLOAD_TEMPLATE_REQUEST,
  UPLOAD_TEMPLATE_SUCCESS,
  UPLOAD_TEMPLATE_FAILURE,
  UPLOAD_TEMPLATE_PROGRESS,
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILURE,
  FETCH_EXAM_SUBJECT_REQUEST,
  FETCH_EXAM_SUBJECT_SUCCESS,
  FETCH_EXAM_SUBJECT_FAILURE,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_MARKS_REQUEST,
  FETCH_MARKS_SUCCESS,
  FETCH_MARKS_FAILURE,
  FETCH_STORE_QUIZ_REQUEST,
  FETCH_STORE_QUIZ_SUCCESS,
  FETCH_STORE_QUIZ_FAILURE,
  POST_QUIZ_REPORT_REQUEST,
  POST_QUIZ_REPORT_SUCCESS,
  POST_QUIZ_REPORT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_USERNAME,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_SEND_TO_UMS_REQUEST,
  FETCH_SEND_TO_UMS_SUCCESS,
  FETCH_SEND_TO_UMS_FAILURE,
  FETCH_SEND_TO_UNI_REQUEST,
  FETCH_SEND_TO_UNI_SUCCESS,
  FETCH_SEND_TO_UNI_FAILURE,
  FETCH_SEND_TO_ATT_REQUEST,
  FETCH_SEND_TO_ATT_SUCCESS,
  FETCH_SEND_TO_ATT_FAILURE,
  UNLOCK_DATA_REQUEST,
  UNLOCK_DATA_SUCCESS,
  UNLOCK_DATA_FAILURE,
  RESET_STATE,
  ERROR,
} from '../action/actionType';

const finalApiUrl = "https://uatapi.teamleaseedtech.com";
const contextApiUrl = "https://uatexamresult.teamleaseedtech.com";

export const loginAndGenerateToken = (username, password) => async (dispatch) => {
  if (!username || !password) {
    dispatch({ type: ERROR, payload: 'Username or password is missing' });
    return;
  }

  try {
    // Login request
    const loginResponse = await axios.post(`${contextApiUrl}/api/login`, { username, password });
    if (loginResponse.data && loginResponse.status === 200) {
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: FETCH_USERNAME, payload: { username, password } });

      // Save username and password to localStorage
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);

      // Store the access token from loginResponse and print it
      const accessToken = loginResponse?.data?.access_token; // Adjust based on your API response structure
      localStorage.setItem('accessToken', accessToken);
      // Call the token generation API
      const tokenResponse = await axios.post(
        `${finalApiUrl}/api/account/generatettletoken`,
        { username: 'UMS', password: '7453c618-30f7-48e2-89d1-3d160140df70' }
      );

      if (tokenResponse.data && tokenResponse.status === 200) {
        const finalToken = tokenResponse.data.token;
        localStorage.setItem('finalToken', finalToken);
        dispatch({ type: TOKEN_SUCCESS, payload: finalToken });
        return finalToken; // Return finalToken for further use
      } else {
        throw new Error('Token generation failed');
      }
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error.message });
    throw error; // Propagate error to the caller
  }
};

// Example of simplified fetch action creator with check
const createFetchActionCreator = (type, requestType, successType, failureType, url, body) => {
  return async (dispatch, getState) => {
    const { universities } = getState();
    if (universities[type]?.length === 0) {
      dispatch({ type: requestType });

      try {
        const response = await axios.post(url, body, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('finalToken')}` }
        });

        if (response.data) {
          dispatch({ type: successType, payload: response.data });
        } else {
          throw new Error(`Failed to fetch ${type}`);
        }
      } catch (error) {
        dispatch({ type: failureType, payload: error.message });
      }
    }
  };
};

// Fetch universities action
export const fetchUniversities = () => {
  return createFetchActionCreator(
    'universities',
    UNIVERSITIES_REQUEST,
    UNIVERSITIES_SUCCESS,
    UNIVERSITIES_FAILURE,
    `${finalApiUrl}/api/Master/GetUniversity`,
    {}
  );
};


export const fetchBatches = (UnvId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BATCHES_REQUEST });

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetBatches`,
        { UnvId }, // Include the UnvId in the request body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch({ type: FETCH_BATCHES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_BATCHES_FAILURE, payload: error.message });
    }
  };
};

export const fetchCourses = (BatchId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COURSES_REQUEST });

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetCourses`,
        { BatchId }, // Include the UnvId in the request body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_COURSES_FAILURE, payload: error.message });
    }
  };
};

export const fetchSemester = (CourseId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SEM_REQUEST });

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/Getsemesters`,
        { CourseId }, // Include the UnvId in the request body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch({ type: FETCH_SEM_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_SEM_FAILURE, payload: error.message });
    }
  };
};

export const fetchExam = (SemesterId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_EXAM_REQUEST });

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetExams`,
        { SemesterId }, // Include the UnvId in the request body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch({ type: FETCH_EXAM_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_EXAM_FAILURE, payload: error.message });
    }
  };
};

export const fetchExamSubject = (ExamId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_EXAM_SUBJECT_REQUEST });

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetSubjects`,
        { ExamId }, // Include the UnvId in the request body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch({ type: FETCH_EXAM_SUBJECT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_EXAM_SUBJECT_FAILURE, payload: error.message });
    }
  };
};


export const fetchGrades = (universityId, courseId, quizId, assessmentId, assessementname, assessementtype, syncType) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_GRADES_REQUEST });
    try {
      const response = await axios.get(`${contextApiUrl}/api/sync-grades`, {
        params: {
          universityid: universityId,
          courseid: courseId,
          quizid: quizId,
          assessementid: assessmentId,
          assessementname: assessementname,
          assessementtype: assessementtype,
          synctype: syncType,
        }
      });

      dispatch({ type: FETCH_GRADES_SUCCESS, payload: response.data });
      return response; // Return the response so it can be used in handleSyncClick
    } catch (error) {
      dispatch({ type: FETCH_GRADES_FAILURE, payload: error.message });
      throw error; // Throw the error to handle it in handleSyncClick
    }
  };
};


// Fetch manual template action
export const fetchManualTemplate = (universityid, courseid, quizid, assessementid, assessementname, assessementType, synctype) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MANUAL_TEMPLATE_REQUEST });
    try {
      const response =
        await axios.get(
          `${contextApiUrl}/api/manual-template?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&assessementtype=${assessementType}&synctype=${synctype}`
        );
      dispatch({ type: FETCH_MANUAL_TEMPLATE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_MANUAL_TEMPLATE_FAILURE, payload: error.message });
    }
  };
};

// Fetch template action
export const fetchTemplate = (universityid, courseid, quizid, assessementid, assessementname, synctype) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TEMPLATE_REQUEST });
    try {
      const response = await axios.get(`${contextApiUrl}/api/download-template?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&synctype=${synctype}`, {
        responseType: 'blob', // Important for downloading files
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sample template.xlsx'); // Replace with the desired file name and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: FETCH_TEMPLATE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_TEMPLATE_FAILURE, payload: error.message });
    }
  };
};

// Fetch template action
export const fetchDownloadTemplate = (universityid, courseid, quizid, assessementid, assessementname, assessementType) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TEMPLATE_REQUEST });
    try {
      const response = await axios.get(`${contextApiUrl}/api/download-quiz-report?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&assessementtype=${assessementType}`, {
        responseType: 'blob', // Important for downloading files
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Quiz Sample template.xlsx'); // Replace with the desired file name and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: FETCH_TEMPLATE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_TEMPLATE_FAILURE, payload: error.message });
    }
  };
};

// Redux action to upload template
export const uploadTemplate = (file) => async (dispatch) => {
  dispatch({ type: UPLOAD_TEMPLATE_REQUEST });

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(
      `${contextApiUrl}/api/upload-template`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const progress = Math.round((event.loaded * 100) / event.total);
          dispatch({ type: UPLOAD_TEMPLATE_PROGRESS, payload: progress });
        },
      }
    );

    if (response.status === 200) {
      dispatch({ type: UPLOAD_TEMPLATE_SUCCESS, payload: response.data });
      return { success: true };
    } else {
      dispatch({
        type: UPLOAD_TEMPLATE_FAILURE,
        payload: response.data.error || 'Upload failed',
      });
      return { success: false, message: response.data.error || 'Upload failed' };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error || error.message || 'An unexpected error occurred';
    dispatch({ type: UPLOAD_TEMPLATE_FAILURE, payload: errorMessage });
    return { success: false, message: errorMessage };
  }
};



// Fetch topics action
export const fetchTopics = (universityId, lmsCourseId) => {
  return async (dispatch, getState) => {
    const { universities } = getState();

    // Check if topics are already fetched
    if (universities.topics.length === 0) {
      dispatch({ type: FETCH_TOPICS_REQUEST });

      try {
        const response = await axios.get(`${contextApiUrl}/api/topics?courseid=${lmsCourseId}&universityid=${universityId}`);
        dispatch({ type: FETCH_TOPICS_SUCCESS, payload: response.data });
      } catch (error) {
        dispatch({ type: FETCH_TOPICS_FAILURE, payload: error.message });
      }
    }
  };
};

export const fetchAssessmentRequest = () => ({
  type: 'FETCH_ASSESSMENT_REQUEST'
});

export const fetchAssessmentSuccess = (data) => ({
  type: 'FETCH_ASSESSMENT_SUCCESS',
  payload: data
});

export const fetchAssessmentFailure = (error) => ({
  type: 'FETCH_ASSESSMENT_FAILURE',
  payload: error
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const fetchAssessment = (examId) => {
  return async (dispatch) => {
    dispatch(fetchAssessmentRequest());

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetAssessment`,
        { ExamId: examId },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch(fetchAssessmentSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssessmentFailure(error.message));
    }
  };
};

export const fetchSubjectsRequest = () => ({
  type: 'FETCH_SUBJECTS_REQUEST'
});

export const fetchSubjectsSuccess = (data) => ({
  type: 'FETCH_SUBJECTS_SUCCESS',
  payload: data
});

export const fetchSubjectsFailure = (error) => ({
  type: 'FETCH_SUBJECTS_FAILURE',
  payload: error
});

export const fetchSubjects = (examId, assessmentId) => {
  return async (dispatch) => {
    dispatch(fetchSubjectsRequest());

    try {
      const response = await axios.post(
        `${finalApiUrl}/api/Master/GetSubjects`,
        { ExamId: examId, AssessmentId: assessmentId },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json' // Adjust content type if necessary
          }
        }
      );

      dispatch(fetchSubjectsSuccess(response.data));
    } catch (error) {
      dispatch(fetchSubjectsFailure(error.message));
    }
  };
};

export const fetchResources = (universityId, courseId) => async dispatch => {
  dispatch({ type: FETCH_RESOURCES_REQUEST });

  try {
    const response = await fetch(`${contextApiUrl}/api/resources?universityid=${universityId}&courseid=${courseId}`);
    const data = await response.json();

    dispatch({
      type: FETCH_RESOURCES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_RESOURCES_FAILURE,
      payload: error.message
    });
  }
};


export const fetchMarksRequest = () => ({
  type: FETCH_MARKS_REQUEST
});

export const fetchMarksSuccess = (data) => ({
  type: FETCH_MARKS_SUCCESS,
  payload: data
});

export const fetchMarksFailure = (error) => ({
  type: FETCH_MARKS_FAILURE,
  payload: error
});

export const fetchMarks = (universityId, courseId, quizId, assessmentId, assessmentName, assessementtype) => {
  return async (dispatch) => {
    dispatch(fetchMarksRequest());
    try {
      const response = await fetch(`${contextApiUrl}/api/detailed-marks?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementid=${assessmentId}&assessementname=${encodeURIComponent(assessmentName)}&assessementtype=${assessementtype}`);
      const data = await response.json();
      if (response.ok) {
        dispatch(fetchMarksSuccess(data));
      } else {
        dispatch(fetchMarksFailure(data.error));
      }
    } catch (error) {
      dispatch(fetchMarksFailure(error.toString()));
    }
  };
};

export const fetchStoreQuizReportRequest = () => ({
  type: FETCH_STORE_QUIZ_REQUEST
});

export const fetchStoreQuizReportSuccess = (data) => ({
  type: FETCH_STORE_QUIZ_SUCCESS,
  payload: data
});

export const fetchStoreQuizReportFailure = (error) => ({
  type: FETCH_STORE_QUIZ_FAILURE,
  payload: error
});


export const storeQuizReport = (universityid, courseid, quizid, assessementid, assessementname, assessementType, notify) => {
  return async (dispatch) => {
    dispatch(fetchStoreQuizReportRequest());

    try {
      const response = await axios.post(
        `${contextApiUrl}/api/store-quiz-report?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&assessementtype=${assessementType}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(fetchStoreQuizReportSuccess(response.data));
      notify && notify(response.data.message, { variant: "success" });
    } catch (error) {
      dispatch(fetchStoreQuizReportFailure(error.message));
    }
  };
};

const postQuizReportRequest = () => ({
  type: POST_QUIZ_REPORT_REQUEST,
});

const postQuizReportSuccess = (data) => ({
  type: POST_QUIZ_REPORT_SUCCESS,
  payload: data,
});

const postQuizReportFailure = (error) => ({
  type: POST_QUIZ_REPORT_FAILURE,
  payload: error,
});

export const postQuizReport = (universityId, courseId, quizId, assessmentId, assessmentName, assessmentType, username, notify) => async (dispatch) => {
  dispatch(postQuizReportRequest());
  try {
    const response = await axios.post(`${contextApiUrl}/api/store-quiz-report?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementid=${assessmentId}&assessementname=${assessmentName}&assessementtype=${assessmentType}&username=${username}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
          'Content-Type': 'application/json',
        },
      });
    dispatch(postQuizReportSuccess(response.data));
    notify && notify(response.data.message, { variant: "success" });
  } catch (error) {
    if (error.response && error.response.status === 400) {
      notify && notify(error.response.data.message, { variant: "warning" });
    } else {
      notify && notify(error.message, { variant: "error" });
    }
    dispatch(postQuizReportFailure(error.message));
  }
};


export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });

  try {
    // Retrieve the accessToken from localStorage
    const accessToken = localStorage.getItem('accessToken');
    // Call your logout API endpoint with Authorization header
    await axios.post(
      `${contextApiUrl}/api/logout`, {},
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    // Clear localStorage
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('finalToken');
    localStorage.removeItem('accessToken');

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: LOGOUT_FAILURE,
      payload: error.message,
    });
  }
};
export const fetchUserData = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });

  try {
    // Retrieve the accessToken from localStorage
    const accessToken = localStorage.getItem('accessToken');

    // Fetch user data
    const response = await axios.get(
      `${contextApiUrl}/api/user`,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    // Extract role from the response data
    const { role } = response.data;

    // Store role in localStorage immediately after fetching
    localStorage.setItem('role', role);

    // Dispatch success action with role and other data
    dispatch({ type: FETCH_USER_SUCCESS, payload: { role, ...response.data } });
  } catch (error) {
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchStatus = (universityId, courseId, quizId, type, notify) => {
  return async (dispatch, getState) => {
    const { universities } = getState();

    if (universities.topics.length === 0) {
      dispatch({ type: FETCH_STATUS_REQUEST });

      try {
        const response = await axios.get(`${contextApiUrl}/api/status-check?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&type=${type}`);
        if (response.data.status === "error") {
          console.error("Error 404: Not Found", response.data);
          dispatch({ type: FETCH_STATUS_FAILURE, payload: 'Status not found' });
          return 'error';
        }
        dispatch({ type: FETCH_STATUS_SUCCESS, payload: response.data });
        
        if (notify && response.data.message) {
          notify(response.data.message, { variant: "success" });
        }

        return 'success';
      } catch (error) {
        const errorMessage = error.response && error.response.data ? error.response.data.message : error.message;

        if (error.response && error.response.status === 404) {
          console.error("Error 404: Not Found", error.response.data);
        } else {
          console.error("Error", error.response);
        }

        dispatch({ type: FETCH_STATUS_FAILURE, payload: errorMessage });
        
        if (notify && errorMessage) {
          notify(errorMessage, { variant: "error" });
        }

        return 'error';
      }
    } else {
      return 'success';
    }
  };
};


export const fetchSendToUms = (
  universityid, courseid, quizid, assessementid, assessementname, batchid, umsCourseid, 
  umsSemesterid, umsExamid, umsExamSubjectid, umsSubjectCode, assessementtype,username, notify
) => async (dispatch) => {
  dispatch({ type: FETCH_SEND_TO_UMS_REQUEST });

  try {
    const response = await axios.get(
      `${contextApiUrl}/api/send-to-ums?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&batchid=${batchid}&umsCourseid=${umsCourseid}&umsSemesterid=${umsSemesterid}&umsExamid=${umsExamid}&umsExamSubjectid=${umsExamSubjectid}&umsSubjectCode=${umsSubjectCode}&assessementtype=${assessementtype}&username=${username}`
    );

    const { message, details: { Result } } = response.data;

    // Notify success
    if (notify) {
      notify(`${message} ${Result}`, { variant: "success" });
    }

    dispatch({
      type: FETCH_SEND_TO_UMS_SUCCESS,
      payload: response.data
    });
    
  } catch (error) {
    const backendErrorMessage = error.response?.data?.details || error.message;
    
    // Extract "cURL error" from the error message if it exists
    const curlErrorMatch = backendErrorMessage.match(/cURL error \d+/);
    const displayError = curlErrorMatch ? curlErrorMatch[0] : backendErrorMessage;

    // Notify error
    if (notify) {
      notify(`Error: ${displayError}`, { variant: "error" });
    }

    dispatch({
      type: FETCH_SEND_TO_UMS_FAILURE,
      payload: displayError
    });
  }
};


export const fetchSendToUNI = (
  universityid, courseid, quizid, assessementid, assessementname, batchid, umsCourseid, 
  umsSemesterid, umsExamid, umsExamSubjectid, umsSubjectCode, assessementtype,username, notify
) => async (dispatch) => {
  dispatch({ type: FETCH_SEND_TO_UNI_REQUEST });

  try {
    const response = await axios.get(
      `${contextApiUrl}/api/send-to-university?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&batchid=${batchid}&umsCourseid=${umsCourseid}&umsSemesterid=${umsSemesterid}&umsExamid=${umsExamid}&umsExamSubjectid=${umsExamSubjectid}&umsSubjectCode=${umsSubjectCode}&assessementtype=${assessementtype}&username=${username}`
    );

    const { message, details: { Result } } = response.data;

    // Notify success
    if (notify) {
      notify(`${message} ${Result}`, { variant: "success" });
    }

    dispatch({
      type: FETCH_SEND_TO_UNI_SUCCESS,
      payload: response.data
    });
    
  } catch (error) {
    const backendErrorMessage = error.response?.data?.details || error.message;
    
    // Extract "cURL error" from the error message if it exists
    const curlErrorMatch = backendErrorMessage.match(/cURL error \d+/);
    const displayError = curlErrorMatch ? curlErrorMatch[0] : backendErrorMessage;

    // Notify error
    if (notify) {
      notify(`Error: ${displayError}`, { variant: "error" });
    }

    dispatch({
      type: FETCH_SEND_TO_UNI_FAILURE,
      payload: displayError
    });
  }
};


export const fetchSendToAttendance = (
  universityid, courseid, quizid, assessementid, assessementname, batchid, umsCourseid, 
  umsSemesterid, umsExamid, umsExamSubjectid, umsSubjectCode, assessementtype,username, notify
) => async (dispatch) => {
  dispatch({ type: FETCH_SEND_TO_ATT_REQUEST });

  try {
    const response = await axios.get(
      `${contextApiUrl}/api/attendance?universityid=${universityid}&courseid=${courseid}&quizid=${quizid}&assessementid=${assessementid}&assessementname=${assessementname}&batchid=${batchid}&umsCourseid=${umsCourseid}&umsSemesterid=${umsSemesterid}&umsExamid=${umsExamid}&umsExamSubjectid=${umsExamSubjectid}&umsSubjectCode=${umsSubjectCode}&assessementtype=${assessementtype}&username=${username}`
    );

    const { message, details: { Result } } = response.data;

    // Notify with variant "success"
    if (notify) {
      notify(`${message} ${Result}`, { variant: "success" });
    }

    dispatch({
      type: FETCH_SEND_TO_ATT_SUCCESS,
      payload: response.data
    });
    
  } catch (error) {
    const backendErrorMessage = error.response?.data?.details || error.message;
    
    // Extract "cURL error 35" from the error message if it exists
    const curlErrorMatch = backendErrorMessage.match(/cURL error \d+/);
    const displayError = curlErrorMatch ? curlErrorMatch[0] : backendErrorMessage;

    dispatch({
      type: FETCH_SEND_TO_ATT_FAILURE,
      payload: displayError
    });

    // Notify with variant "error" and show only the cURL error or default error message
    if (notify) {
      notify(`Error: ${displayError}`, { variant: "error" });
    }
  }
};



export const unlockData = (universityId, courseId, quizId, assessementid, assessmentType, username) => {
  return dispatch => {
    dispatch({ type: UNLOCK_DATA_REQUEST });
    axios.post(`${contextApiUrl}/api/unlock-data?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementid=${assessementid}&assessementtype=${assessmentType}&username=${username}`)
    .then(response => {
      dispatch({ type: UNLOCK_DATA_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: UNLOCK_DATA_FAILURE, payload: error.message });
    });
  };
};