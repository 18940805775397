import axios from 'axios';
import {
  FETCH_UNIVERSITIES_REQUEST,
  FETCH_UNIVERSITIES_SUCCESS,
  FETCH_UNIVERSITIES_FAILURE,
  FETCH_COURSE_CATEGORIES_REQUEST,
  FETCH_COURSE_CATEGORIES_SUCCESS,
  FETCH_COURSE_CATEGORIES_FAILURE,
  FETCH_COURSES_REQUEST_CAMS,
  FETCH_COURSES_SUCCESS_CAMS,
  FETCH_COURSES_FAILURE_CAMS,
  FETCH_CAMS_RESOURCES_REQUEST,
  FETCH_CAMS_RESOURCES_SUCCESS,
  FETCH_CAMS_RESOURCES_FAILURE,
  FETCH_GRADES_REQUEST_CAM,
  FETCH_GRADES_SUCCESS_CAM,
  FETCH_GRADES_FAILURE_CAM,
  FETCH_CAMS_DETAILED_MARKS_REQUEST,
  FETCH_CAMS_DETAILED_MARKS_SUCCESS,
  FETCH_CAMS_DETAILED_MARKS_FAILURE,
  FETCH_QUIZ_REPORT_REQUEST,
  FETCH_QUIZ_REPORT_SUCCESS,
  FETCH_QUIZ_REPORT_FAILURE,
  POST_QUIZ_REPORT_REQUEST_CAMS,
  POST_QUIZ_REPORT_SUCCESS_CAMS,
  POST_QUIZ_REPORT_FAILURE_CAMS,
  FETCH_STATUS_REQUEST_CAM,
  FETCH_STATUS_SUCCESS_CAM,
  FETCH_STATUS_FAILURE_CAM,
  FETCH_CAMS_SEND_TO_UMS_REQUEST,
  FETCH_CAMS_SEND_TO_UMS_SUCCESS,
  FETCH_CAMS_SEND_TO_UMS_FAILURE,
  CAMS_UNLOCK_DATA_REQUEST, 
  CAMS_UNLOCK_DATA_SUCCESS, 
  CAMS_UNLOCK_DATA_FAILURE,
  CAMS_ATTENDANCE_REQUEST ,
  CAMS_ATTENDANCE_SUCCESS,
  CAMS_ATTENDANCE_FAILURE,
  FETCH_CAMS_SEND_TO_UNS_REQUEST,
  FETCH_CAMS_SEND_TO_UNS_SUCCESS,
  FETCH_CAMS_SEND_TO_UNS_FAILURE,
  RESET_STATE
} from '../action/actionType';


const contextApiUrl = "https://uatexamresult.teamleaseedtech.com"

export const fetchUniversitiesCams = () => async dispatch => {
  dispatch({ type: FETCH_UNIVERSITIES_REQUEST });

  try {
    const response = await fetch(`${contextApiUrl}/api/cams-univerisities`);
    const data = await response.json();

    dispatch({
      type: FETCH_UNIVERSITIES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_UNIVERSITIES_FAILURE,
      payload: error.message
    });
  }
};

export const fetchCourseCategories = (universityId) => async dispatch => {
  dispatch({ type: FETCH_COURSE_CATEGORIES_REQUEST });

  try {
    const response = await fetch(`${contextApiUrl}/api/cams-course-categories?universityid=${universityId}`);
    const data = await response.json();

    dispatch({
      type: FETCH_COURSE_CATEGORIES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_COURSE_CATEGORIES_FAILURE,
      payload: error.message
    });
  }
};

export const fetchCourses = (universityId, categoryId) => async dispatch => {
  dispatch({ type: FETCH_COURSES_REQUEST_CAMS });

  try {
    const response = await fetch(`${contextApiUrl}/api/cams-courses?universityid=${universityId}&categoryid=${categoryId}`);
    const data = await response.json();

    dispatch({
      type: FETCH_COURSES_SUCCESS_CAMS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FETCH_COURSES_FAILURE_CAMS,
      payload: error.message
    });
  }
};

// Action creators
export const fetchCamsResourcesRequest = () => ({
  type: FETCH_CAMS_RESOURCES_REQUEST,
});

export const fetchCamsResourcesSuccess = (resources) => ({
  type: FETCH_CAMS_RESOURCES_SUCCESS,
  payload: resources,
});

export const fetchCamsResourcesFailure = (error) => ({
  type: FETCH_CAMS_RESOURCES_FAILURE,
  payload: error,
});

export const fetchCamsResources = (universityId, courseId) => {
  return async (dispatch) => {
    dispatch(fetchCamsResourcesRequest());

    try {
      const response = await fetch(`${contextApiUrl}/api/cams-resources?universityid=${universityId}&courseid=${courseId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch CAMS resources');
      }
      const data = await response.json();
      dispatch(fetchCamsResourcesSuccess(data)); // Dispatch success action with data
    } catch (error) {
      dispatch(fetchCamsResourcesFailure(error.message)); // Dispatch failure action with error message
    }
  };
};


export const fetchGradesCams = (universityId, courseId, quizId, syncType, assessementType) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_GRADES_REQUEST_CAM });
    try {
      const response = await axios.get(`${contextApiUrl}/api/cams-sync-grades?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&synctype=${syncType}&assessementtype=${assessementType}`);
      dispatch({ type: FETCH_GRADES_SUCCESS_CAM, payload: response.data });
      return response; // Return the response so it can be used in handleSyncClick
    } catch (error) {
      dispatch({ type: FETCH_GRADES_FAILURE_CAM, payload: error.message });
      throw error; // Throw the error to handle it in handleSyncClick
    }
  };
};

const fetchCamsDetailedMarksRequest = () => ({
  type: FETCH_CAMS_DETAILED_MARKS_REQUEST
});

const fetchCamsDetailedMarksSuccess = (data) => ({
  type: FETCH_CAMS_DETAILED_MARKS_SUCCESS,
  payload: data
});

const fetchCamsDetailedMarksFailure = (error) => ({
  type: FETCH_CAMS_DETAILED_MARKS_FAILURE,
  payload: error
});

export const fetchCamsDetailedMarks = (universityId, courseId, quizId, assessementType) => {
  return (dispatch) => {
    dispatch(fetchCamsDetailedMarksRequest());
    axios.get(`${contextApiUrl}/api/cams-detailed-marks?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementtype=${assessementType}`)
      .then(response => {
        dispatch(fetchCamsDetailedMarksSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchCamsDetailedMarksFailure(error.message));
      });
  };
};

// Fetch template action
export const fetchQuizReport = (universityId, courseId, quizId, assessementType) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_QUIZ_REPORT_REQUEST });
    try {
      const response = await axios.get(`${contextApiUrl}/api/cams-download-quiz-report?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementtype=${assessementType}`, {
        responseType: 'blob', // Important for downloading files
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Cams Sample template.xlsx'); // Replace with the desired file name and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: FETCH_QUIZ_REPORT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_QUIZ_REPORT_FAILURE, payload: error.message });
    }
  };
};
export const resetState = () => ({
  type: RESET_STATE,
});


const postQuizReportRequest = () => ({
  type: POST_QUIZ_REPORT_REQUEST_CAMS,
});

const postQuizReportSuccess = (data) => ({
  type: POST_QUIZ_REPORT_SUCCESS_CAMS,
  payload: data,
});

const postQuizReportFailure = (error) => ({
  type: POST_QUIZ_REPORT_FAILURE_CAMS,
  payload: error,
});

export const postQuizReport = (universityId, courseId, quizId, assessmentType, username, notify) => async (dispatch) => {
  dispatch(postQuizReportRequest());
  try {
    const response = await axios.post(`${contextApiUrl}/api/cams-store-quiz-report?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementtype=${assessmentType}&username=${username}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('finalToken')}`,
          'Content-Type': 'application/json',
        },
      });
    dispatch(postQuizReportSuccess(response.data));
    notify && notify(response.data.message, { variant: "success" });
  } catch (error) {
    if (error.response && error.response.status === 400) {
      notify && notify(error.response.data.message, { variant: "warning" });
    } else {
      notify && notify(error.message, { variant: "error" });
    }
    dispatch(postQuizReportFailure(error.message));
  }
};

export const fetchStatusCAMS = (universityId, courseId, quizId, type, notify) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_STATUS_REQUEST_CAM });
    try {
      const response = await axios.get(`${contextApiUrl}/api/cams-status-check?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&type=${type}`);      
      if (response.data.status === "error") {
        console.error("Error 404: Not Found", response.data);
        dispatch({ type: FETCH_STATUS_FAILURE_CAM, payload: 'Status not found' });
        return 'error';
      }
      dispatch({ type: FETCH_STATUS_SUCCESS_CAM, payload: response.data });

      if (notify && response.data.message) {
        notify(response.data.message, { variant: "success" });
      }

      return response.data;
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data.message : error.message;

      if (error.response && error.response.status === 404) {
        console.error("Error 404: Not Found", error.response.data);
      } else {
        console.error("Error", error.response);
      }

      dispatch({ type: FETCH_STATUS_FAILURE_CAM, payload: errorMessage });

      if (notify && errorMessage) {
        notify(errorMessage, { variant: "error" });
      }

      return 'error';
    }

  };
};

export const fetchCamsSendToUms = (universityid, courseCategoryId, courseid, quizid, assessementtype) => async (dispatch) => {
  dispatch({ type: FETCH_CAMS_SEND_TO_UMS_REQUEST });

  try {
    const response = await axios.get(`${contextApiUrl}/api/cams-send-to-ums?universityid=${universityid}&courseCategoryId=${courseCategoryId}&courseid=${courseid}&quizid=${quizid}&assessementtype=${assessementtype}`);
    dispatch({
      type: FETCH_CAMS_SEND_TO_UMS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_CAMS_SEND_TO_UMS_FAILURE,
      payload: error.message
    });
  }
};

export const camsUnlockData = (universityId, courseId, quizId, assessmentType, username) => {
  return dispatch => {
    dispatch({ type: CAMS_UNLOCK_DATA_REQUEST });
    axios.post(`${contextApiUrl}/api/cams-unlock-data?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementtype=${assessmentType}&username=${username}`)
    .then(response => {
      dispatch({ type: CAMS_UNLOCK_DATA_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: CAMS_UNLOCK_DATA_FAILURE, payload: error.message });
    });
  };
};

export const fetchCAMSAttendance = (universityId, courseId, quizId,assessmentType) => {
  return dispatch => {
    dispatch({ type: CAMS_ATTENDANCE_REQUEST });
    axios.get(`${contextApiUrl}/api/cams-attendance?universityid=${universityId}&courseid=${courseId}&quizid=${quizId}&assessementtype=${assessmentType}`)
      .then(response => {
        dispatch({ type: CAMS_ATTENDANCE_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: CAMS_ATTENDANCE_FAILURE, payload: error.message });
      });
  };
};


export const fetchCamsSendToUniversity = (universityid, courseCategoryId, courseid, quizid, assessementtype) => async (dispatch) => {
  dispatch({ type: FETCH_CAMS_SEND_TO_UNS_REQUEST });

  try {
    const response = await axios.get(`${contextApiUrl}/api/send-to-university-cams?universityid=${universityid}&courseCategoryId=${courseCategoryId}&courseid=${courseid}&quizid=${quizid}&assessementtype=${assessementtype}`);
    dispatch({
      type: FETCH_CAMS_SEND_TO_UNS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_CAMS_SEND_TO_UNS_FAILURE,
      payload: error.message
    });
  }
};